import type {
  ChainModifiers,
  Entry,
  EntryFieldTypes,
  EntrySkeletonType,
  LocaleCode,
} from 'contentful';
import type { TypeButtonSkeleton } from './TypeButton';
import type { TypeContextualButtonSkeleton } from './TypeContextualButton';
import type { TypeOrientedMediaSkeleton } from './TypeOrientedMedia';

export interface TypeIllustratedTextFields {
  erw: EntryFieldTypes.Symbol;
  media?: EntryFieldTypes.EntryLink<TypeOrientedMediaSkeleton>;
  title?: EntryFieldTypes.RichText;
  description?: EntryFieldTypes.RichText;
  button?: EntryFieldTypes.EntryLink<TypeButtonSkeleton | TypeContextualButtonSkeleton>;
  color?: EntryFieldTypes.Symbol;
  isIcon?: EntryFieldTypes.Boolean;
  image?: EntryFieldTypes.AssetLink;
  settings?: EntryFieldTypes.Object;
  readMoreText?: EntryFieldTypes.Text;
  readMoreCloseButton?: EntryFieldTypes.EntryLink<TypeContextualButtonSkeleton>;
  logos?: EntryFieldTypes.Array<EntryFieldTypes.AssetLink>;
}

export type TypeIllustratedTextSkeleton = EntrySkeletonType<
  TypeIllustratedTextFields,
  'illustratedText'
>;
export type TypeIllustratedText<
  Modifiers extends ChainModifiers = 'WITHOUT_UNRESOLVABLE_LINKS',
  Locales extends LocaleCode = LocaleCode,
> = Entry<TypeIllustratedTextSkeleton, Modifiers, Locales>;

export function isTypeIllustratedText<Modifiers extends ChainModifiers, Locales extends LocaleCode>(
  entry: Entry<EntrySkeletonType, Modifiers, Locales>,
): entry is TypeIllustratedText<Modifiers, Locales> {
  return entry.sys.contentType.sys.id === 'illustratedText';
}
