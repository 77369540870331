import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { ReactNode } from 'react';
import { breakpoints, colors, spacing } from '../../../utils/styleguide';
import { ImageStyleProps } from '../../contentful-elements/Image';
import ButtonPromptWrapper from '../ButtonPromptWrapper';
import PrimaryButton, { ButtonColor } from '../buttons/PrimaryButton';
import Typography from '../text/Typography';
import PropsComponent from '../types/PropsComponent';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  height: 100%;

  *:last-child {
    margin-bottom: 0;
  }
`;

const Overline = styled(Typography)`
  color: ${colors.blackSecondary};
  margin-bottom: ${spacing[3]}px;
  ${breakpoints.desktop} {
    margin-bottom: ${spacing[4]}px;
  }
`;

const Title = styled.div`
  margin-bottom: ${spacing[3]}px;
  ${breakpoints.desktop} {
    margin-bottom: ${spacing[4]}px;
  }
`;

const ImageWrapper = styled.div`
  width: 70%;
  ${breakpoints.desktop} {
    margin-bottom: ${spacing[6]}px;
  }
`;

const Description = styled(Typography)`
  margin-bottom: ${spacing[5]}px;
`;

const ButtonWrapper = styled(ButtonPromptWrapper)`
  margin: ${spacing[2]}px 0;
`;

type BuildingBlockInfoProps = {
  overline?: string;
  title?: ReactNode;
  content?: ReactNode;
  description?: ReactNode;
  buttonLabel?: string;
  buttonPrompt?: string;
  buttonColor?: ButtonColor;
  largeButton?: boolean;
  href?: string;
  Logo?: PropsComponent<ImageStyleProps>;
  className?: string;
};

function BuildingBlockInfo({
  overline,
  title,
  content,
  description,
  buttonLabel,
  buttonPrompt,
  buttonColor,
  largeButton,
  href,
  Logo,
  className,
}: BuildingBlockInfoProps) {
  return (
    <Wrapper className={className}>
      {overline && <Overline variant={'overline'}>{overline}</Overline>}
      <Title>{title}</Title>
      <Description variant="body" tag="div">
        {description}
      </Description>
      {content}
      {buttonLabel && (
        <ButtonWrapper prompt={buttonPrompt}>
          <PrimaryButton
            color={buttonColor}
            label={buttonLabel}
            tag="a"
            href={href}
            size={largeButton ? 'large' : 'small'}
          />
        </ButtonWrapper>
      )}
      {Logo && (
        <ImageWrapper>
          <Logo
            css={css`
              max-width: 100%;
              width: 100%;
            `}
          />
        </ImageWrapper>
      )}
    </Wrapper>
  );
}

export default BuildingBlockInfo;
