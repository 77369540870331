import styled from '@emotion/styled';
import BuildingBlockInfo from '../component-library/layout/BuildingBlockInfo';
import HighlightSectionBleedLayout from '../component-library/layout/HighlightSection/HighlightSectionBleedLayout';
import SectionBackground from '../component-library/SectionBackground';
import ContentfulImage, { ImageStyleProps } from './Image';
import { PageSectionComponent } from './pageSections';
import RichText from './RichText';
import sectionToCompatibleProps from './util/sectionToCompatibleProps';
import sectionToCompatibleServerSideProps from './util/sectionToCompatibleServerSideProps';
import { getVideoById, Video } from '../../utils/api/videoApi';
import BackgroundVideo from '../component-library/media/BackgroundVideo';
import { getStreamingSrc } from '../../utils/helpers/video';
import PropsComponent from '../component-library/types/PropsComponent';
import { isTypeIllustratedText } from '../../../@types/generated/TypeIllustratedText';
import { isTypeImageLink } from '../../../@types/generated';

const FullImage = styled.div`
  height: 100%;
  width: 100%;

  > div {
    width: 100%;
    height: 100%;
  }
`;

const StyledImage = styled(ContentfulImage)<{ imageFit?: string }>`
  object-fit: ${(props) => props.imageFit || 'cover'};
  width: 100%;
  height: 100%;
`;

type ServerSideProps = {
  landscapeVideo?: Video;
  portraitVideo?: Video;
};

const HighlightSectionBleed: PageSectionComponent<ServerSideProps> = ({
  section,
  serverSideProps: { landscapeVideo, portraitVideo },
}) => {
  const { references, buildingBlock } = section.fields;
  const {
    id,
    overline,
    title,
    description,
    withCheckmarks,
    reversed,
    media: { image },
    button,
    backgroundColor,
  } = sectionToCompatibleProps({ section });

  const bullets = buildingBlock?.fields.bullets || references;

  let mediaComponent: JSX.Element = <></>;
  if (image) {
    mediaComponent = (
      <FullImage>
        <StyledImage asset={image} />
      </FullImage>
    );
  } else if (landscapeVideo || portraitVideo) {
    const videoLargeScreenUrl = landscapeVideo && getStreamingSrc({ video: landscapeVideo });
    const videoSmallScreenUrl = portraitVideo && getStreamingSrc({ video: portraitVideo });
    mediaComponent = (
      <BackgroundVideo
        aspectRatios={{ desktop: 0, tablet: 0, mobile: 0 }}
        showControls={false}
        videoLargeScreenUrl={videoLargeScreenUrl}
        videoSmallScreenUrl={videoSmallScreenUrl}
        isMaxWidth={true}
      />
    );
  }

  let logo: PropsComponent<ImageStyleProps> | undefined;

  if (bullets && bullets.length === 1) {
    const bullet = bullets[0];
    if (bullet && (isTypeImageLink(bullet) || isTypeIllustratedText(bullet))) {
      logo = (props: ImageStyleProps) => <ContentfulImage asset={bullet.fields.image} {...props} />;
    }
  }

  return (
    <SectionBackground id={id} backgroundColor={backgroundColor}>
      <HighlightSectionBleedLayout
        reverseColumns={reversed}
        content={
          <BuildingBlockInfo
            overline={overline}
            title={<RichText document={title} />}
            description={<RichText document={description} withCheckmark={withCheckmarks} />}
            buttonLabel={button?.label}
            buttonPrompt={button?.prompt}
            buttonColor={button?.colorContext}
            href={button?.href}
            Logo={logo}
          />
        }
        media={mediaComponent}
      />
    </SectionBackground>
  );
};

HighlightSectionBleed.getCustomServerSideProps = async ({ section }) => {
  const { videoIdLandscape, videoIdPortrait } = sectionToCompatibleServerSideProps({ section });
  const landscapeVideo = await getVideoById(videoIdLandscape);
  const portraitVideo = await getVideoById(videoIdPortrait);

  return { landscapeVideo, portraitVideo };
};

export default HighlightSectionBleed;
