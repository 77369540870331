import styled from '@emotion/styled';
import { ReactNode } from 'react';
import { breakpoints, spacing } from '../../../../utils/styleguide';

const Wrapper = styled.section`
  padding-bottom: ${spacing[10]}px;
  height: fit-content;
  ${breakpoints.tablet} {
    padding: 0;
    min-height: 520px;
    display: flex;
    flex-direction: ${(props: { reverseColumns?: boolean }) =>
      props.reverseColumns ? 'row-reverse' : 'row'};
    align-items: stretch;
    max-width: 1920px;
    margin: 0 auto;
  }

  ${breakpoints.desktop} {
    min-height: 720px;
  }
`;

const SubsectionWrapper = styled.div`
  ${(props: { contained?: boolean }) => (props.contained ? 'height: 375px;' : '')};
  ${breakpoints.tablet} {
    flex: 1 1 0;
    min-width: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    height: auto;
  }
`;

const StretchWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  ${breakpoints.tablet} {
    min-width: 0;
  }
  ${(props: { stretch?: boolean }) => (props.stretch ? 'height: 100%;' : '')};
`;

const ContentWrapper = styled.div`
  margin: ${spacing[6]}px ${spacing[4]}px 0;

  ${breakpoints.tablet} {
    margin: ${spacing[10]}px;
  }
  ${breakpoints.desktop} {
    margin: ${spacing[10]}px ${spacing[11]}px;
  }
`;

type HighlightSectionLayoutBleedProps = {
  reverseColumns?: boolean;
  media: JSX.Element | null;
  content: JSX.Element;
  fixedMobileHeight?: boolean;
};

const HighlightSectionBleedLayout = ({
  reverseColumns = false,
  media,
  content,
  fixedMobileHeight,
}: HighlightSectionLayoutBleedProps) => {
  return (
    <Wrapper reverseColumns={reverseColumns}>
      {media && (
        <Subsection stretch contained={!fixedMobileHeight}>
          {media}
        </Subsection>
      )}
      <Subsection>
        <ContentWrapper>{content}</ContentWrapper>
      </Subsection>
    </Wrapper>
  );
};

const Subsection = ({
  children,
  stretch,
  contained,
}: {
  children: ReactNode;
  stretch?: boolean;
  contained?: boolean;
}) => (
  <SubsectionWrapper contained={contained}>
    <StretchWrapper stretch={stretch}>{children}</StretchWrapper>
  </SubsectionWrapper>
);

export default HighlightSectionBleedLayout;
